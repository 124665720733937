import  React from 'react';
//,{ lazy, Suspense }
import {
	ActivityIndicator,
	Clipboard,
	Button,
	//SafeAreaView,
	//FlatList,
	Image,
	Share,
	StyleSheet,
	Text,
	ScrollView,
	View,
	TouchableOpacity,
	TextInput
} from 'react-native';
//import { ImagePicker, Permissions } from 'expo';
import * as ImagePicker from 'expo-image-picker';
import * as Permissions from 'expo-permissions';
//import * as Locations from 'expo-location';
//import * as Camera from 'expo-camera;'
import uuid from 'uuid';

import firebase from './config/firebase';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/custom.css';
import {
	heightPercentageToDP as hp,
	widthPercentageToDP as wp,
   } from 'react-native-responsive-screen'


/*const AvatarComponent = lazy(() => import('./AvatarComponent'));

const renderLoader = () => <p>Loading</p>;

const DetailsComponent = () => (
	<Suspense fallback={renderLoader()}>
	<AvatarComponent />
	</Suspense>
)*/

export default class App extends React.Component {
	
	state = {
		image: null,
		uploading: false,
		googleResponse: null,
		save_disable:'disabled',
		error:false,
		custom_response:null,
		
	};
	
	async componentDidMount() {
		await Permissions.askAsync(Permissions.CAMERA_ROLL);
		await Permissions.askAsync(Permissions.CAMERA);
	}

	render() {
		let { image,custom_response,googleResponse } = this.state;
		//console.log(custom_response);
		var labels = ['Name','Date of Birth','Expiry Date','Licence Number','Class','Type','Address'];
		var details = [];
		var Valuess= [];
		if(this.state.googleResponse !=undefined && this.state.googleResponse.responses[0].textAnnotations !=undefined)
		{
			//var count = this.state.custom_response.length;
			var multiline="";
			var values="Empty";
			var address ="";
			var card_index=0;
			var licence_index=0;
			//Valuess[0]=
			//	'0'=null,'1'=null,'2'=null,'3'=null,'4'=null,'5'=null,'6'=null,
			//];
			this.state.custom_response.map((item,index)=>{
				
				if(this.state.custom_response.includes("New South Wales, Australia") == true)
				{	
					if(this.state.custom_response[index-2] =="Driver Licence")
					{
						Valuess[0]=item;
					}
					if(this.state.custom_response[index-1] =="Date of Birth")
					{
						Valuess[1]=item;
					}
					if(this.state.custom_response[index-1] =="Expiry Date")
					{
						Valuess[2]=item;
					}
					if(this.state.custom_response[index-1] =="Licence No.")
					{
						Valuess[3]=item;
					}
					if(this.state.custom_response[index-1] =="Licence Class")
					{
						Valuess[4]=item;
						Valuess[5]=item;
					}
					if(this.state.custom_response[index-2] =="Card Number" || this.state.custom_response[index+1] =="Licence No.")
					{
						address = address +'\n'+ item;
						Valuess[6]=address.trim();
					}
				}else if(this.state.custom_response.includes("AUSTRALIAN CAPITAL TERRITORY") == true)
				{	
					if(this.state.custom_response[index-2] =="DRIVER LICENCE")
					{
						Valuess[0]=item;
					}
					if(this.state.custom_response[index].includes("Date of Birth") == true)
					{
						var dob = item.split(" ");
						Valuess[1]=dob[3];
					}
					if(this.state.custom_response[index-1] =="Expiry Date")
					{
						Valuess[2]=item;
					}
					if(this.state.custom_response[index-1] =="Licence No.")
					{
						Valuess[3]=item;
					}
					if(this.state.custom_response[index-1] =="Licence Class")
					{
						Valuess[4]=item;
						Valuess[5]=item;
					}
					if(this.state.custom_response[index-2] =="Card Number" || this.state.custom_response[index+1] =="Licence No.")
					{
						address = address +'\n'+ item;
						Valuess[6]=address.trim();
					}
				}else if(this.state.custom_response.includes("Queensland Government") == true)
				{	
					if(this.state.custom_response[index-2] =="Driver Licence")
					{
						Valuess[0]=item;
					}
					if(this.state.custom_response[index].includes("DOB") == true)
					{
						var dob = item.split(" ");
						Valuess[1]=dob[1]+' '+dob[2]+' '+dob[3];
					}
					if(this.state.custom_response[index-1] =="Class Type Effective Expiry")
					{
						var others=item.split(" ");
						Valuess[2]=others[3];
						Valuess[4]=others[0];
						Valuess[5]=others[1];
					}
					if(this.state.custom_response[index-1] =="LICENCE NO.")
					{
						Valuess[3]=item;
					}
					
					
				}else if(this.state.custom_response.includes("WESTERN AUSTRALIA") == true)
				{	
					if(this.state.custom_response[index-2] =="DRIVER'S LICENCE")
					{
						Valuess[0]=item;
					}
					if(this.state.custom_response[index].includes("Date of Birth") == true)
					{
						var dob = item.split(" ");
						Valuess[1]=dob[3];
					}
					if(this.state.custom_response[index-1] =="Expiry Date")
					{
						Valuess[2]=item;
					}
					if(this.state.custom_response[index-1] =="Licence No.")
					{
						Valuess[3]=item;
					}
					if(this.state.custom_response[index-1] =="Licence Class")
					{
						Valuess[4]=item;
						Valuess[5]=item;
					}
					if(this.state.custom_response[index-2] =="Card Number" || this.state.custom_response[index+1] =="Licence No.")
					{
						address = address +'\n'+ item;
						Valuess[6]=address.trim();
					}
				}
				else if(this.state.custom_response.includes("VICTORIA AUSTRALIA") == true)
				{	
					if(this.state.custom_response[index-2] =="DRIVER LICENCE")
					{
						Valuess[0]=item;
					}
					if(this.state.custom_response[index-1] =="DATE OF BIRTH")
					{
						var dob_exp=item.split(" ");
						Valuess[1]=dob_exp[1];
						Valuess[2]=dob_exp[0];
					}
					
					if(this.state.custom_response[index-1] =="LICENCE NO.")
					{
						Valuess[3]=item;
					}
					if(this.state.custom_response[index-1] =="LICENCE TYPE CONDITIONS")
					{
						Valuess[4]=item;
						Valuess[5]=item;
					}
					if(this.state.custom_response[index-2] =="LICENCE NO." || this.state.custom_response[index+1] =="LICENCE EXPIRY")
					{
						address = address +'\n'+ item;
						Valuess[6]=address.trim();
					}
				}else if(this.state.custom_response.includes("NORTHERN TERRITORY OF AUSTRALIA") == true)
				{	
					var cls_typ_all="";
					if(this.state.custom_response[index-4] =="DRIVER LICENCE")
					{
						var dname=item.split(".");
						Valuess[0]=dname[1];
					}
					if(this.state.custom_response[index].includes("DATE OF BIRTH") ==true)
					{
						var dob_exp=item.split(" ");
						Valuess[1]=dob_exp[1];
						Valuess[2]=dob_exp[0];
					}
					
					if(this.state.custom_response[index].includes("LICENCE NO") == true)
					{
						var licno=item.split(" ");
						Valuess[3]=licno[3];
					}
					if(this.state.custom_response[index].includes("CLASS") == true)
					{
						cls_typ_all=item;
						var cls_typ=item.split(" ");
						Valuess[4]=cls_typ[2];
						Valuess[5]=cls_typ[2];
					}
					if(this.state.custom_response[index-1] ==cls_typ_all || this.state.custom_response[index+1] =="Home")
					{
						//var addresss = item;
						address = address +'\n'+ item;
						Valuess[6]=address.trim();
					}
				}
				
			});
			
			//console.log(Valuess);
			
			for(let i=0; i < Valuess.length; i++)
			{

				details.push(
					<View key={i}>
						
						<View>
							<Text style={{flex: 3,fontWeight:'bold',padding:10}}>{labels[i]}</Text>
							{labels[i]=="Address" ?
							<TextInput multiline numberOfLines={5} style={{flex: 3,padding:10,backgroundColor: '#C0C0C0'}} value={Valuess[i]}/>
							:<TextInput  numberOfLines={5} style={{flex: 3,padding:10,backgroundColor: '#C0C0C0'}} value={Valuess[i]}/>}
						</View>
					</View>
				);
			}
		
		}
			return (
				<div className="container-fluid bg-light  container_height mb-5" >
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pad_z">
						<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							
						<View >
						<div className=" col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<ScrollView
								
							>
								
								<div className=" col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<View >
								
								{this.state.error && (
									<div className="alert alert-info mt-1">	
									<Text style={{ padding:10}}>No Data retrieved, please Analyze again</Text>
									</div>
								)}
								
									<div className="row justify-content-md-center mt-5">
									<div className="col-offset-6 col-lg-2 mb-2 ">
										<Button 
											
											onPress={this._pickImage}
											title="Pick an image"
										/>
										
									</div>	
									<div className="clearfix"></div>
									<div className="col-offset-6  col-lg-2 mb-2">
										<Button  onPress={this._takePhoto} title="Take a photo" />
									</div>
									<div className="clearfix"></div>
									<div className="col-offset-6 col-lg-2 mb-2 ">
										
										<Button color="#28a745" disabled={this.state.save_disable} /*onPress={() => this.submitToGoogle()}*/ title="Save"/>
									</div>	
									<div className="clearfix"></div>
									</div>
									<div className="clearfix"></div>
									
									<div className="row">
									<div className=" col-lg-4 col-md-4 col-sm-12 col-xs-12  float-left">
									{this._maybeRenderImage()}
									{this._maybeRenderUploadingOverlay()}
									</div>
									<div className="bg-light col-lg-8 col-md-8 col-sm-12 col-xs-12 float-left">
									
									
									
										{details}
									
									</div>
									</div>
									
									
								</View>
								</div>
							</ScrollView>
							</div>	
						</View>
						</div>
						</div>
					</div>
				</div>
			);
		
		
	}
	
	organize = array => {
		return array.map(function(item, i) {
			return (
				<View key={i}>
					<Text>{item}</Text>
				</View>
			);
		});
	};
	
	_maybeRenderUploadingOverlay = () => {
		if (this.state.uploading) {
			return (
				<View
					style={[
						StyleSheet.absoluteFill,
						{
							backgroundColor: 'rgba(0,0,0,0.4)',
							alignItems: 'center',
							justifyContent: 'center'
						}
					]}
				>
					<ActivityIndicator color="#fff" animating size="large" />
				</View>
			);
		}
	};
//googleResponse
	_maybeRenderImage = () => {
		let { image  } = this.state;
		if (!image) {
			return;
		}

		
		return (
			<div className="row">
			<div className="pad_left_right col-lg-12 col-md-12 col-sm-12 col-xs-12 bg-light rounded">
			<View
				
			>
				<div className="pad_left_right col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
				
				<Button
					color="#dc3545"
					style={{ marginBottom: 10 }}
					onPress={() => this.submitToGoogle()}
					title="Analyze!"
					
				/>
				
				
				</div>
				<div className="pl-2 col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
				<View
					
				>
				
					<TouchableOpacity  >
						<Image  source={{ uri: image }} style={styles.responsiveImage} />
					</TouchableOpacity>
					
				</View>
				</div>
				<Text
					onPress={this._copyToClipboard}
					onLongPress={this._share}
					style={{ paddingVertical: 10, paddingHorizontal: 10 }}
				/>

			
			</View>
			</div>
			</div>
			//style={{ width: 500, height: 500 }}
		);
	};

	_keyExtractor = (item, index) => item.id;
//response: {JSON.stringify(item)}
	_renderItem = item => {
		<TextInput>Item: {item.description}</TextInput>
		
		
	
	};

	_share = () => {
		Share.share({
			message: JSON.stringify(this.state.googleResponse.responses),
			title: 'Check it out',
			url: this.state.image
		});
	};

	_copyToClipboard = () => {
		Clipboard.setString(this.state.image);
		alert('Copied to clipboard');
	};

	_takePhoto = async () => {
		let pickerResult = await ImagePicker.launchCameraAsync({
			allowsEditing: false,
			aspect: [4, 3]
		});
		
		this._handleImagePicked(pickerResult);
	};

	_pickImage = async () => {
		let pickerResult = await ImagePicker.launchImageLibraryAsync({
			allowsEditing: true,
			aspect: [4, 3]
		});
		
		this._handleImagePicked(pickerResult);
	};

	_handleImagePicked = async pickerResult => {
		try {
			this.setState({
				googleResponse:null,
				save_disable:'disabled',
			});
			this.setState({ uploading: true });
			var uploadUrl;
			if (!pickerResult.cancelled) {
				uploadUrl = await uploadImageAsync(pickerResult.uri);
				//console.log(uploadUrl);
				this.setState({ image: uploadUrl });
			}
		} catch (e) {
			console.log(e);
			alert('Upload failed, sorry :(');
		} finally {
			this.setState({ uploading: false });
		}
	};

	submitToGoogle = async () => {
		try {
			this.setState({ uploading: true });
			let { image } = this.state;
			let body = JSON.stringify({
				requests: [
					{
						features: [
							{ type: 'LABEL_DETECTION', maxResults: 10 },
							{ type: 'LANDMARK_DETECTION', maxResults: 5 },
							{ type: 'FACE_DETECTION', maxResults: 5 },
							{ type: 'LOGO_DETECTION', maxResults: 5 },
							{ type: 'TEXT_DETECTION', maxResults: 5 },
							{ type: 'DOCUMENT_TEXT_DETECTION', maxResults: 5 },
							{ type: 'SAFE_SEARCH_DETECTION', maxResults: 5 },
							{ type: 'IMAGE_PROPERTIES', maxResults: 5 },
							{ type: 'CROP_HINTS', maxResults: 5 },
							{ type: 'WEB_DETECTION', maxResults: 5 }
						],
						image: {
							source: {
								imageUri: image
							}
						}
					}
				]
			});
			let response = await fetch(
				'https://vision.googleapis.com/v1/images:annotate?key=AIzaSyAgx7ny7S4LBcypo3-So5Vej_MYqQKcU4M',
				{
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json'
					},
					method: 'POST',
					body: body
				}
			);
			
			let responseJson = await response.json();
			console.log(responseJson);
			
				//this.setState({
				//	googleResponse:null
				//});
				
				if(responseJson && responseJson.responses[0].error)
				{
					this.setState({
						googleResponse: null,
						error : true,
						save_disable:'disabled',
						uploading: false,
						
					});

				}else{
					var responsess=responseJson.responses[0].textAnnotations[0].description;
					responsess = responsess.split("\n");
					console.log(responsess);
					this.setState({
						googleResponse: responseJson,
						error : false,
						save_disable:'',
						uploading: false,
						custom_response: responsess
						
					});
				}
			
			
		} catch (error) {
			
			console.log(error);
		}
	};
}

async function uploadImageAsync(uri) {
	const blob = await new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.onload = function() {
			resolve(xhr.response);
		};
		xhr.onerror = function(e) {
			console.log(e);
			reject(new TypeError('Network request failed'));
		};
		xhr.responseType = 'blob';
		xhr.open('GET', uri, true);
		xhr.send(null);
	});

	const ref = firebase
		.storage()
		.ref()
		.child(uuid.v4());
	const snapshot = await ref.put(blob);

	//blob.close();

	return await snapshot.ref.getDownloadURL();
}

const styles = StyleSheet.create({
	container: {
		width:'100%',
		height:'auto',
		flex: 12,
		backgroundColor: '#eee',
		paddingBottom: 100
	},
	developmentModeText: {
		marginBottom: 20,
		color: 'rgba(0,0,0,0.4)',
		fontSize: 14,
		lineHeight: 19,
		textAlign: 'center'
	},
	contentContainer: {
		paddingTop: 30,
		marginBottom:50
	},

	getStartedContainer: {
		alignItems: 'center',
		marginHorizontal: 50,
		width:'40%',
	},

	getStartedText: {
		fontSize: 24,
		fontWeight:'bold',
		color: 'rgba(96,100,109, 1)',
		lineHeight: 24,
		textAlign: 'center'
	},

	helpContainer: {
		marginTop: 15,
		alignItems: 'center',
		paddingHorizontal:10,
	},
	input: {
		height: 40,
		margin: 12,
		borderWidth: 1,
	},
	fixToText: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		
	},
	responsiveImage: {
		width: wp('75%'),
		height: hp('30%'),
		alignSelf:'center',
		resizeMode:'contain',
		
	},
	labelText:{
		fontWeight:'bold',
	}
});