// firebase.js

import firebase from 'firebase';

var config = {
  apiKey: 'AAAAUB4tZfA:APA91bHfnYju1iMcBEUEeMV1TjFIAl1JSTyf2rTH7TrkgaBJSPhT_AbQomGUPe6iWp7Vvs3iaVB7pG6PhCeWCUlQMEQYlR6OgNKh57FrDr4-48hNzYIPj4MJV_zEkzLes1ZFoTIGOUBH',
  authDomain: 'https://fleetzync.firebaseapp.com',
  databaseURL: 'https://fleetzync-default-rtdb-firebaseio.com',
  projectId: 'fleetzync',
  storageBucket: 'gs://fleetzync.appspot.com',
  messagingSenderId: '344103675376',
};

firebase.initializeApp(config);

export default firebase;